
.top-ribbon {
    background: linear-gradient(to bottom, rgba(255, 217, 0, 0.728),  rgba(255, 217, 0, 0.334));
    color: black;
    font-size: 18px;
    font-weight: 700;
    margin: 0 auto;
    
     padding: 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height:25px;
    width: 260px;
    border-left: 1px solid rgba(0, 0, 0, 0.701); 
    border-right: 1px solid rgba(0, 0, 0, 0.701); 
    border-top: 1px solid rgba(0, 0, 0, 0.701); 
}

.bottom-ribbon {
    border-left: 1px solid rgba(0, 0, 0, 0.701); 
    border-right: 1px solid rgba(0, 0, 0, 0.701); 
    border-bottom: 1px solid rgba(0, 0, 0, 0.701); 
 
    background: linear-gradient(to top, rgba(255, 217, 0, 0.728),  rgba(255, 217, 0, 0.334));
    color: black;
    font-size: 17px;
    font-weight: 500;
 
     border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    z-index: -10;
    height:25px;
    width: 260px;
    margin: 0 auto;

}
