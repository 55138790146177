
.navbar-outer-container{
  position:fixed;
  top:0;
  width:100vw;
  background-color:  rgb(243, 239, 227);
  z-index:20;
}

.navbar-outer-container a{
    color:black;
    text-decoration: none;
    font-weight:500; margin-bottom: 5px;;}

.navbar-image-conatiner{
    height:fit-content;
 
    background-color:  rgb(243, 239, 227);
}
.navbar-container{
   
    position: sticky;
  
    height:0px;
    
    top:0
}

.navbar-container img{
    height:100px;
    width:auto;
    display:block;
    margin: 0px auto;

}

.navbar{
    background-color:  rgb(243, 239, 227);
    transform: translateX(-100%);
 z-index:-1;
    transition: ease-in .5s transform;
    display: flex;
    flex-direction: column-reverse;
}

.getstarted{
    margin: 50px 0;
    
}

.slide{
    transform: translateX(0);
 
}

.navbar{
    padding-bottom:1000px;
}


.navbar-options{
    list-style: none;;
    text-align: center;
    padding-top:50px;
    background-color:  rgb(243, 239, 227);
}
.navbar-options li{
    padding: 10px;
    font-family: roboto condensed;
}
 

.navbar-options li:hover{
    cursor: pointer;
   
    transform: scale(1.1);

}

.hamburger{
    position: absolute;
    right:30px;
    font-size: 25px;
   top:25px;
    width:fit-content;
    z-index:5
}

.hamburger:hover{
  cursor:pointer
}

 


@media(min-width:800px){

    .navbar{
        padding-bottom:0px;
    }
    
    

    .getstarted{
        margin:  0;
        height:100%;
        transform: translateY(-200%);
        transition: transform 0.5s ease-in-out;
    }

    .show-get-started{
        transform: translateY(0%);
    }

    .navbar-options li{
        padding: 0 10px;
    }
    .hamburger{
        display: none;
    }

    .navbar-container{
 display:flex;
 justify-content: space-between;
 flex-wrap: wrap;
    }
    .navbar-container > * {
        flex: 1 1 auto; /* Allow items to grow and shrink as needed */
        min-width: 100px; /* Set a minimum width to avoid items getting too small */
      }

    .navbar{
        transform: translateX(0%);    
        display: flex;;
        flex-direction: column;
       justify-content: flex-end;
       align-items: center;
    }

    .navbar-options{
        display: flex;
        height:fit-content;
            padding-top: 0px;
            align-items: flex-end;
       
    }

    .navbar-container{
 height:fit-content;
 width:95%;
 margin: 0 auto;

    }

    .navbar-outer-container{
        border-bottom: 1px solid black;
    }
}