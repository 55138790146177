.get-started-container{
   
  height: 100%;
   background-color:  rgba(243, 239, 227, 0);
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}



.get-started-button{
    height:40px;
    width:200px;
    background-color: rgba(0, 0, 0, 0.942);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.404);
    color:white;
    font-family: Roboto condensed;

    
}

.large .little-letters{
    font-family: Roboto condensed;
    font-size: 15px;
}

.little-letters{
    font-family: Roboto condensed;
    font-size: 9px;
}

.large{
    height:80px;
    width:300px;
    font-size:30px;
    border:1px solid white;
}
.get-started-button:hover{
    cursor: pointer;
    transform: scale(1.1);
}