
.Belt {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin:0;
    padding:0;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.63);
    border:1px dotted white;
   
}

.Belt div {
    width: 20px;
    height: 20px;
 
}

.black {
    background-color: black;
}

.blue {
    background-color: rgba(0, 98, 255, 0.898);
}

.purple {
    background-color: rgba(191, 0, 255, 0.898);
}

.red {
    background-color: red;
}

.brown {
    background-color: #8B4513
}

.BeltContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
}
