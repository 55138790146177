.FAQContainer {
    margin: auto;
    background-color: rgb(253, 253, 253);
    padding-top: 100px;
 
  }
  
   
  
    .FAQ{
      width:fit-content;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
     
    
    .FAQFlexbox{
      display: flex;
      justify-content: center;
      align-items: center;
 
      
    }
  
    .FAQFlexbox h1{
      text-align: center;
    }
  
   
  
   .Second{
    margin-left:20px;
   }
  
   .Third{
   margin-left:40px;
   }
    .faq-controls{
      display:flex;
      justify-content: center;
      margin: 20px 0;
      width:100%;
   
    }
    
    .faq-controls >*{
      margin: 5px 2px;
      height:30px;
      width:175px;
      display: block;
      background-color: rgba(0, 0, 0, 0.709);
      color: white;
      border: 2px white solid;
      font-size: 15px;
      font-weight: 300;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.84);
       transition: background-color 0.3s ease;
    }
    
    .faq-controls >*:hover{
      cursor: pointer;
 
    }
  .VideoContainer{
   
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top:30px;
  }

  .faq-item-container {
 width:90%
  }

  .faq-item{
    width:90%
    
  }

  .faq-answer a{
    text-decoration: underline;
  }

  .faq-answer {
    width:98%;
    margin:0 auto;
    font-weight: 200;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.872);
    color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 0.5px solid rgb(255, 255, 255);
    border-left: 0.5px solid rgb(255, 255, 255);
    border-bottom: 0.5px solid rgb(255, 255, 255);
    transition: opacity 0.5s ease; /* Optional: fade in/out effect */
  }

  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.903);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid rgb(255, 255, 255);
    color: white;
    padding: 10px;
  }
  
    
  .faq-question h2 {
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
    margin-bottom: 2px;
    padding-left: 5px;
  }
  
  .faq-item-container {
    display: flex;
    justify-content: flex-start;
    width: 90%;
    margin: 2px auto;
  }
  
  .faq-item {
    width:100%
  }
  
  .faq-item-container button {
    height: 35px;
    width: 35px;
    background-color: #05050500;
    border: none;
    font-size: 20px;
    color: white;
  }
  
  .faq-item-container button:hover {
    cursor: pointer;
  }
  
  /* Container for the answer content */
  .faq-answer-wrapper {
    overflow: hidden; /* Prevents overflow during transitions */
  }

  .faq-free-trial{
    text-decoration: underline;
  }

  .faq-free-trial:hover{
cursor: pointer;
  }
  
  
    @media(min-width: 800px){

   
    
      .FAQ-and-video >*{
        width:50vw; 
      }
      
      .faq-controls >*{
        width:400px;
        margin: 0 5px;
      }
  
    }
   
    
   
    
      
  
        