
/* ParallaxSection.css */
.parallax-container {
    height: 600px; /* Adjust the height as needed */
    background-image: url('../Media/parallax.jpg'); /* Replace with your image path */

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
     position: relative;;
       /*  background-attachment: fixed;*/
  }

  .parallax-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
   }
  
  .content {
    padding: 20px;
    text-align: center;
    background-color: #f4f4f4;
  }
  