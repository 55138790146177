
.landing-container{
    height:fit-content;
 
    overflow: hidden;
    
}

.image-container{
    width:100%;
    position:relative;
    height:fit-content
} 
   
    

.leaf{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; /* Ensure it's above the other image */
    max-width:70%;
 
}

 .max{
    
    object-position: bottom;
   padding:0;
 
   height:100vh;
   width: 100vw;
   object-fit: cover;
   object-position: 50% 90%;  
 
}


.before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(48, 50, 46, 0.565); /* Semi-transparent overlay */
    pointer-events: none; /* Allows clicks to pass through */
}

.get-started-landing-container{
 
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 6;
     
   
   
}



.get-started-landing-container >*{
   margin-bottom:10px
       
       
    }
    



 
.landing-container h1{
    position: absolute;
    text-align: center;
    color:white;
    top: 40%;
    font-weight:300;
    margin:0 auto;
    width:100%
}
