.purchase-container {
 
  overflow: scroll;
  width: 80vw;
  }
  
  .purchase-form {
    width: 40vw;
  
    margin: 0 auto;
    font-family: Roboto condensed;
  }
  
  label, input, label, option{
    font-family: Roboto condensed;
 
  }
   
  .purchase-form-container h2{
    margin-top:10px;
  }
  
  .purchase-form h2 {
    color: #333;
    margin-bottom: 20px;  
  }

  .purchase-form-container{
    margin: 0 auto;
   }
 
  .transaction-details{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
 align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
 
  

  

  /* Make the button span across both columns */
  .purchase-form-container .submit-btn {
     display:block;
    background-color: #000000;
    color: white;
 
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 20px auto;
    width: 100px;
    height:20px;
    text-align: center; 
 
    margin-bottom: 200px;
  }

   
 
  
  
  .submit-btn:hover {
    background-color: #555;
  }
  
  /* Ensure form fields are full width */
  .form-group {
    display: flex;
    flex-direction: column;
  }
  .field-flex{
    display: flex;
    justify-content: space-between;
  }
  .half{
    width:48%;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .form-group input, .form-group select {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
  
  .purchase-summary {
    background-color: #f9f9f9;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    min-width: 300px;
    margin: 0 auto;
    margin-top:70px;
  }
  
  .purchase-summary h3 {
    color: #333;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .purchase-summary p {
    margin: 10px 0;
    font-size: 16px;
  }
  
  hr {
    border: none;
    border-top: 1px solid #ccc;
    margin: 15px 0;
  }

  .close-btn{
  width:100%;
  display: flex;  
  justify-content: flex-end;
 font-size:30px;
 color:black;
 padding:20px;
  }

 .close-btn button{
  background-color: rgba(255, 0, 0, 0);
  border:1px solid rgba(0, 0, 0, 0);
  color:black

 }

 .close-btn button:hover{
  cursor: pointer;
 }


 
.group{
display: flex;
justify-content: center;
  
 
}

.purchase-container img{
  height: 80px;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}

.purchase-form-container h3{
  text-align: center;
  margin-bottom:20px;
}
 
.purchase-form-container button{
  margin: 0 auto;
  display: block;
  margin:5px auto;
  width: 160px;
  height:16px;
  font-family: Roboto condensed;
  color: white;
  background-color: rgba(0, 0, 0, 0.924);
  border-radius: 5px;
  font-size: 15px;
  box-shadow: 2px 2px 2px black;;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.purchase-form-container button:hover{
  cursor: pointer;
  color:black;
  background-color: rgb(231, 231, 231);
}

.button-container{
  display: flex;
  margin: 0 auto;
  width: fit-content
}

  /* Responsive design for smaller screens */
  @media (max-width: 768px) {

  
    .purchase-summary {
      margin-top: 20px;
    }


  
}