.MembershipContainer{
    margin: 30px auto;
    border:rgb(255, 255, 255) 1px solid;
    padding-bottom: 15px ;
    font-size: 1.5rem;
    text-align: center;
    height: 200px;
    width: 250px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgb(183, 181, 181);
    border-radius: 15px;
    transition: scale 0.5s ease-in-out;
    position: relative;
 
    border:2px solid black;
    border-top: none;
}
 
.MembershipContainer:hover{
cursor: default;
scale: 1.1;
 
}


.PurchaseButton {
    height:40px;
    background-color: rgb(15, 14, 14);
    color: white;
    border: 1px solid #ffffff; /* White border */
    border-radius: 10px;
    padding: 15px 30px;
    font-size: 1.2rem;
 
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: 150px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
}

.MembershipContainer a{color:white}
 

 

.PurchaseButton:hover {
    background-color: white;
    color: black;
 
}

.MembershipType{
    font-size: 1.1em;
    padding-top:20px;
    background-color: rgba(0, 0, 0, 0.798);
    display:flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding-bottom: 10px;
    border-bottom:1px solid white;
    color:white;
}

 
 
.Price{
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.billed{
    font-size:0.6em;
    text-transform: capitalize;
}

.Cancel {
    font-size: 0.8rem;
    font-style: italic;
    
}
.promo{
    margin-bottom: 80px
}


@media(min-width:800px){
    .MembershipContainer{
        margin: 50px auto;
         
    }
    
}