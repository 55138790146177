
/* ParallaxSection.css */
.parallax1-container {
    height: 600px; /* Adjust the height as needed */
    background-image: url('../Media/mats1.jpg'); /* Replace with your image path */
     /*  background-attachment: fixed;*/
         background-position:left;
    background-repeat: no-repeat;
    background-size: cover;
     position: relative;;
  }

  .parallax1-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
   }
 