/* Pricing.css */

.PricingContainer {
    padding-top: 50px;
}

.PricingContainer h1 {
   
    text-align: center;
    margin-bottom: 20px;
    
}

.Intro {
    width: 95%;
    margin: 0 auto;
    font-size: 1.5rem;
}

.BulletPoints {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 100px;
}

.BulletPoints li {
    font-size: 1.5rem;
    margin-top: 90px;
}

.icon {
    font-size: 40px;
    margin: 0 auto;
    
}

.BulletHeader {
    font-size: 1.8rem;
    margin-bottom: 15px;
 
}

.Description {
    width: 90%;
  
    font-size: 0.6em;
    font-style: italic;
    margin: 0 auto;
}

.Memberships {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.MembershipButtons {
    width: 150px;
    margin: 0 auto;

}

.PricingButtonContainer {
    width: 90vw;
    margin: 40px auto;
     min-height: fit-content
 
}

.PricingButtonContainer button {
    width: 300px;
    height: 40px;
    margin: 10px auto;
    font-size: 1.2em;
    background-color: black;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    box-shadow: 5px 5px 5px black;
    font-family: Roboto, sans-serif;
    transition: background-color 0.3s, color 0.3s; /* Added transition for smooth hover effect */
    display: block;
}

.PricingButtonContainer button:hover {
    cursor: pointer;
    background-color: white;
    color: black;
}

.Vertical {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
   
}
.booking-page{
    margin-top:50px
}


.Show {
    opacity: 1;
    transform: translateY(0);
}


 
#purchase-button{
    margin: 0;
    width:80px;
    height: 40px;
    font-size: 18px;
    font-family: roboto condensed;
    background-color: black;
    line-height: .9;
     color: white;
    border: 1px solid white;
    border-radius: 5px;
    box-shadow: 2px 2px 3px black;
    text-align: center;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.name-and-price{
    line-height: 1.3;
 }

.pricing-flex p{
    width: 150px;
}

.hst{
    font-size: 12px;
}


.name-of-class{
    font-size: 16px;
}
 
.price {
    font-size: 13px;
}
.pricing-flex{
    position: relative;
    display: flex;
    justify-content: center;
    width:300px;
    margin: 10px auto;   
    align-items: center;
    height: 50px; /* Define an initial height */
    background-color:  rgb(243, 239, 227);
    padding:40px 0;
    border-radius: 10px;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
 }

.pricing-flex img{
height: 50px
}

.promotion{
    margin:0 auto;
    border: 1px solid rgba(0, 0, 0, 0.701);
    background-color: rgb(255, 217, 0, 0.334);

     
 



}
.hide{
    background-color: blue;
}

.big{
    background-color: rgb(255, 255, 255);
height: 250px ;
transform: scale(1.2);
border:1px solid black;
flex-direction: column;
justify-content: flex-start;
align-items: center;
border-radius: 10px;
 position: fixed;
 left:0;
margin: 0 auto;
bottom:0;
width: 100vw;
height:90vh;
z-index: 50;

}
.big p{
width: fit-content;
 
}

.cash-disclaimer{
    font-size: 10px;
    font-style: italic;
}
.cancel-disclaimer{
    font-size: 13px;
    font-style: italic;
}


@media (min-width: 800px) {

  

     
    .Description {
        width: 100%;
    };

 
}



/* Optional: Center class if not defined */
.Center {
    text-align: center;
}
