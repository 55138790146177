.legend-container{
    margin-top:25px;
text-align: center;
}


@media(max-width:800px){
    .legend-container{
    font-size: 12px;
    width:85%;
    margin: 0 auto;
     
    margin-bottom:20px;
    }

}