.ContactContainer{
    background-color:  white;
    color:black;
    padding-bottom: 50px;
    padding-top: 100px;
}


.ContactContainer h1{
    text-align: center;
    padding-bottom: 50px 0;
 
    margin-bottom: 30px;
    
}
.ig-icon{
    margin-right: 4px;
}

.Instagram{
    text-align: left;
    font-size: 30px;
    display: flex;
align-items: center;

}

.ContactContainer h2{
    text-align: center;
    margin-bottom: 30px;
   
}

.ContactContainer li{
    font-family: Sahitya;
    list-style:square;
    margin-left:20px;
    font-weight: 300;
    margin-bottom:5px;
   
}

.ContactContainer ul{
margin-bottom:20px;
}

.Contact span{
   font-size:25px
    }
    

.ContactInfo-and-map{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
   
}

.ContactInfo-and-map img{
    height:250px
}

.ContactInfo-and-map iframe {
    display: block;
    width:300px;
    height:300px;
    border-radius: 15px;
    box-shadow: 10px 10px 10px rgba(255, 255, 255, 0.265);
    margin: 0 auto;
    margin-top: 50px;
 
}

.Phone, .Email, .Address {
  
    margin : 20px 0;
    width:100%;
}

.Indent{
    margin-left:25px;
}

.Contact h1{
    text-align: left;
}

.Contact{
    margin-top:50px;
    font-size: 20px;
}
 
#ContactText{
    font-size: 17px; 
}

.Map{
    padding: 0 20px;
}

@media(min-width:800px){
    .ContactInfo-and-map iframe {
        width:500px;
        height:300px;     
    }

    
.ContactContainer h2{
    text-align: left;
    padding-bottom: 30px 0;
    font-size:35px; 
}

.ContactContainer li{
   
    font-size:20px; 
}

 

    
}