.programs-container{
  
    margin: 0 auto;
    padding:100px 0;
    background-color:  rgb(243, 239, 227);
   
    
}


.book-private{
    display: block;
    background-color: red;
 
    width:130px;
    margin: 0 auto;
    margin-top:20px;
    background-color: rgba(255, 255, 255, 0.197);
    border-radius: 5px;
    font-size: 20px;
    font-family: roboto condensed;
    border: 1px solid white;
 }

.book-private:hover{
   scale: 1.1;
}

.program-image{
    width:350px;
    height:300px;
 
 
    display:block;
 
}

.program-image img{
    width:350px;
    height:300px;
    border-radius: 5%;
 
    display:block;
    left: 50%;
}

.dim{
    filter: brightness(40%);
}

.program-container{
  position:relative;
  text-align: center;
  margin-bottom:20px;
  display: flex;
  justify-content: center;
}

.overlay{position: absolute;
    padding-top: 150px;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.069), rgb(0, 0, 0)80%);
        
        width:350px;
        height:300px;
        z-index: 3;
    
        color: white;
        font-family: roboto condensed;

       border-radius: 5%;
   
     }

     .overlay *>{
        height: fit-content;
        font-family: roboto condensed;
     }

     .overlay h2{
        height: fit-content;
        font-family: roboto condensed;
     }

.program-container:hover{
 cursor:default;
  }
  


 

.program-title {
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; /* Make sure it spans full width */
    text-align: center; /* Center the text */
    color:white;
 
    text-align: center;
    font-weight: 500;
}

.program-title h2{
 
    font-weight: 500;
    font-family: Roboto condensed;
    font-size:35px;
    color: white;
}


.program-container p {
 
font-family: roboto condensed;
color:white;
width: 350px;;
margin: 0 auto;
font-size: 15px;
height:fit-content;
font-weight: 300;
text-align: left;
display: flex;
justify-content: center;
align-items: center;
width:90%;
z-index:5
}

.program-title p{
   width:80%
    }
    
 
 
@media(min-width: 800px){
    .programs{
display:flex;
    }

    .programs{
        display:flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
            }
}