 
*{
    
    margin: 0;
    box-sizing: border-box;
    padding: 0;
     font-family: poppins;
  }

  h6{
    font-weight: 500;
    opacity: .9;
    font-size: 18px;
    text-transform: uppercase;
  }

  a {
    color: inherit; /* or choose a specific color */
    text-decoration: none; /* Optional: removes underline */
  }
  
  a:visited {
    color: inherit; /* or set the desired color for visited links */
  }
  

  html {
    scroll-behavior: smooth;
  }
  

  .center{
    margin: 0 auto;
  }

  h1{
    text-align:center; font-size:30px; text-transform:uppercase; color:#222; letter-spacing:1px;
    font-family:"Fugaz One"; font-weight:400; margin-bottom: 50px;;
   
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    grid-gap: 20px;
    align-items: center;

  }
  
  h1:after, h1:before {
      content: " ";
      display: block;
      border-bottom: 1px solid #ccc;
      border-top: 1px solid #ccc;
      height: 5px;
    background-color:#f8f8f8;
  }

  h2{
    font-weight: 300;
  }

  .crimson{
    color: crimson;
  }

  .center {
    margin: 0 auto;
  }
 

/*  .animate {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
 
  .animate.visible {
    opacity: 1;
    transform: translateY(0);
  } */